/** @format */

import { CenterBigTitle } from '@/types/page';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { motion } from 'framer-motion';
import { FunctionComponent as FC, ReactNode } from 'react';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import SmallArrow from '../../assets/small-arrow.svg';
import SmallCircle from '../../assets/small-circle.svg';
import { Icon } from '../ui/Icon';
import { Container } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { H4, Kicker, P } from '../ui/Typography';

export const TimelineSection: FC<Props> = ({ title, times }) => {
  const odd = times.filter((number, index) => index % 2 === 1);
  const even = times.filter((number, index) => index % 2 === 0);

  return (
    <CustomContainer>
      <TitleWrapper>
        <RichText text={title} customOptions={options} />
        <DragAnimationWrapper>
          <motion.div
            initial={{ opacity: 1, x: 0 }}
            animate={{ opacity: 0, x: -40 }}
            transition={{ duration: 1, yoyo: Infinity, repeat: Infinity }}
            className="rect"
          />
          <div />
        </DragAnimationWrapper>
      </TitleWrapper>
      <TimelineWrapper>
        <FirstRow $length={odd.length}>
          <div></div>
          {odd &&
            odd.map(time => (
              <Time key={time.fields.id}>
                <RichText text={time.fields.body} />
              </Time>
            ))}
        </FirstRow>
        <Divider $length={odd.length >= even.length ? odd.length : even.length}>
          <Icon icon={SmallCircle} />
          <Icon icon={SmallArrow} />
        </Divider>
        <SecondRow $length={even.length}>
          <div></div>
          {even.map(time => (
            <Time key={time.fields.id}>
              <RichText text={time.fields.body} />
            </Time>
          ))}
        </SecondRow>
      </TimelineWrapper>
    </CustomContainer>
  );
};

const DragAnimationWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .rect {
    height: 1.25rem;
    width: 1.25rem;
    background: ${({ theme }) => theme.color.pink};
    border-radius: 50%;
  }
`;

const CustomContainer = styled(Container)`
  overscroll-behavior-x: none;
`;

const Time = styled.div`
  max-width: 98%;
  &:not(:first-child) {
    border-left: 0.125rem solid ${({ theme }) => theme.color.teal.dark};
  }
`;

const Divider = styled.div<{ $length: number }>`
  width: calc(23.75rem * ${({ $length }) => $length});
  min-height: 0.125rem;

  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.125rem;
    background-color: ${({ theme }) => theme.color.teal.dark};
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  i:first-child {
    width: 0.5625rem;
    height: 0.5625rem;
    flex-shrink: 0;
  }
  i:last-child {
    width: 0.8rem;
    height: 0.8rem;
    flex-shrink: 0;
    right: 0;
  }
`;
const FirstRow = styled.div<{ $length: number }>`
  display: grid;
  grid-template-columns: 150px repeat(${({ $length }) => $length}, 23.75rem);

  > div {
    padding-bottom: 1.21rem;
    padding-inline: 0.87rem;
  }
`;
const SecondRow = styled.div<{ $length: number }>`
  display: grid;
  grid-template-columns: 60px repeat(${({ $length }) => $length}, 21rem);

  > div {
    padding-inline: 0.87rem;
    padding-top: 1.21rem;
  }
`;

const options: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_4]: (node, children) => <H4 as="h2">{children}</H4>,
  },
};

const options2: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <Kicker>{text}</Kicker>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
  },
};

const TimelineWrapper = styled(ScrollContainer)`
  position: relative;
  scroll-behavior: initial;
  overflow-x: auto;
  overscroll-behavior-x: none;
  padding-top: clamp(2.9rem, 3.24rem + -1.5111vw, 1.88rem);
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  p {
    em {
      font-family: ${({ theme }) => theme.font.accent};
      font-size: ${({ theme }) => `clamp(${theme.font.size[1.125]}, 1.0417rem + 0.3704vw,${theme.font.size[1.375]});`};
      font-style: normal;
      font-weight: 600;
      line-height: clamp(1.25rem, 1.0833rem + 0.7407vw, 1.75rem);
      letter-spacing: clamp(0.03375rem, 0.0313rem + 0.0111vw, 0.04125rem);
      color: ${({ color, theme }) => color ?? theme.color.pink};
    }
    color: ${({ color, theme }) => color ?? theme.color.teal.dark};
  }
  p:has(> em) {
    margin-bottom: 0.44rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  h2 {
    color: ${({ theme }) => theme.color.pink};
  }
`;

type Props = {
  title: Document;
  times: Entry<CenterBigTitle['fields']>[];
};
